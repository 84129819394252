<template>
  <div>
    <div class="vs-row mt-5 block">
      <div class="vs-col xl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full pb-4 px-4" v-for="(item, index) in products" :key="item.index" :offset="index > 0 ? 2 : 0">
        <el-card class="card" :body-style="{ padding: '0px' }">
          <div class="cardBody">
            <img v-if="item.discount_percent !== '0.0 %'" class="w-13 h-14 absolute ml-2" src="@/assets/images/discountpadge.svg" alt="">
            <span v-if="item.discount_percent !== '0.0 %'" class="absolute pt-1 pl-1 ml-2 text-white text-sm">{{item.discount_percent}}</span>
            <img :src="item.image" alt="" class="image">
            <!-- <img :src="item.banner" class="image"> -->
          </div>
          <div class="footer" style="padding: 14px;">
            <span>{{item.name_en}}</span>
            <!-- <span>{{item.title}}</span> -->
            <div class="mt-1">
              <div class="mx-1 bottom">
                <span class="text-blue line-through">{{item.pre_price}} KWD</span><span class="text-orange ml-3">{{item.price}} KWD</span>
              </div>
              <a class="text-blue cursor-pointer" @click="productView(item)">
                <span class="link">View Details</span> 
                <!-- <span class="link" @click="openActivityView(item)">View Details</span> -->
                <span class="material-icons float-right mr-1" style="margin-top:-4px;">open_in_new</span>
              </a>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios.js'

export default {
  props: ['categoryId'],
  data () {
    return {
      products: [],
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      }
    }
  },
  watch: {
    '$route.params.categoryId' () {
      this.products = []
      this.loadproducts()
    }
  },
  methods: {
    loadproducts () {
      this.$vs.loading()
      axios.get(`v2/category/${this.$route.params.categoryId}/products`)
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.products = response.data.payload.data
          this.$vs.loading.close()
        }
      })
    },
    productView (data) {
      this.$router.push({
        name: 'product-view',
        params: {
          categoryId: this.$route.params.categoryId, 
          productId: data.id
        }
      })
    }
  },
  created () {
    this.products = []
    this.loadproducts()
  }
}
</script>

<style scoped>
.el-card {
  height: 377px;
}
.bottom {
  font-size: 13px;
  margin-bottom: -0px;
}
.link {
  padding-bottom: 10px;
  float: right;
  font-size: 13px;
}
.card .footer .mt-1 a {
  visibility: hidden;
}
.card:hover .footer .mt-1 a {
  visibility: visible;
}
.image {
  width: 100%;
  height: 240px;
  display: block;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn {
  color: gray;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2%;
  right: 2%;
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn2 {
  color:#00DCDC;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2%;
  right: 15%;
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn:hover, .cardBody .btn2:hover {
  background-color: #999;
}
</style>