<template>
  <div>
    <div class="vs-row hero">
      <div class="hero-text self-center mx-20">
        <p class="text-5xl font-bold pl-4">Store</p>
        <p class="px-4 text-lg">Lorem ipsum, dolor sit amet, consectetur adipisicing elit, sed</p>
        <vs-breadcrumb :items="items" separator="chevron_right"></vs-breadcrumb>
      </div>
    </div>

    <div class="ml-8 mr-8 my-5 pt-8">
      <el-tabs v-model="activeTab" type="card">
        <el-tab-pane :name="String(category.id)" v-for="category in categories" :key="category.index" :label="category.name_en">
          <Products :categryId="$route.query.categoryId"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Products from './components/Products.vue'
import axios from '../axios.js'

export default {
  data () {
    return {
      categories: [],
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      items: [
        {
          title: 'Home',
          url: 'home',
        },
        {
          title: 'Store',
          active: true
        }
      ],
      activeTab: '1'
    }
  },
  watch: {
    'activeTab' (val) {
      this.$router.push({
        name: 'store',
        params: {
          categoryId: val
        }
      }).catch(() => {})
    }
  }, 
  methods: {
    loadCategories () {
      this.$vs.loading()
      axios.get('v2/categories', {headers: this.headers})
      .then((response) => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.categories = response.data.payload.data
          this.categories.unshift({
            id: 0,
            name_en: 'All',
            name_ar: 'الكل',
          })
        }
        this.$vs.loading.close()
      })
    }
  },
  components: {
    Products
  },
  created () {
    this.activeTab = String(this.$route.params.categoryId)
    this.loadCategories()
  }
}
</script>

<style scoped>
.hero {
  background-image: url('../assets/images/footer.jpg');
}
.height {
  opacity: 0.75;
}
.transbox {
  width: 30%;
  padding: 30px 20px 30px 20px;
  background-color: #000;
  opacity: 0.75;
}
</style>